<template>
  <!-- 登记列表 -->
  <div class="padding24" id="dengji">
    <div v-show="$route.meta.isShow">
      <div class="top_tips">
        <div
          v-for="(item, index) in resultArr"
          :key="index"
          @click="topClick(item)"
        >
          <span v-if="item.auditStatus == 1">提交未审核</span>
          <span v-if="item.auditStatus == 2">一级审核通过</span>
          <span v-if="item.auditStatus == 3">二级审核通过</span>
          <span v-if="item.auditStatus == 4">不通过</span>
          :
          {{ item.total }}
        </div>
      </div>
      <div class="top">
        <div class="left">
          <a-select
            v-model="getData.auditStatus"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="auditStatusChange"
          >
            <a-select-option v-for="item in auditStatusArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="getData.productCatValue"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="productCatValueChange"
          >
            <a-select-option v-for="item in productCatValueArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="getData.autAcpMethodValue"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="autAcpMethodValueChange"
          >
            <a-select-option
              v-for="item in autAcpMethodValueArr"
              :key="item.key"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="getData.productCreateNatValue"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="productCreateNatValueChange"
          >
            <a-select-option
              v-for="item in productCreateNatValueArr"
              :key="item.key"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-select
            v-model="getData.autBelMethodValue"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="autBelMethodValueChange"
          >
            <a-select-option
              v-for="item in autBelMethodValueArr"
              :key="item.key"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
          <span>
            创建日期 :
            <a-range-picker
              :value="rangePicker"
              format="YYYY-MM-DD"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
              @change="onChangeDate"
            />
          </span>
          <span>
            更新日期 :
            <a-range-picker
              :value="rangePicker2"
              format="YYYY-MM-DD"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
              @change="onChangeUpdDate"
            />
          </span>
        </div>
        <div class="right"></div>
      </div>
      <div class="top">
        <div class="left">
          <a-input
            v-model="getData.copyrightsName"
            placeholder="请输入著作权姓名"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="getData.productName"
            placeholder="请输入作品名称"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            class="mr10"
            icon="search"
            type="primary"
            @click="searchClick"
          >
            搜索
          </a-button>
          <a-button
            v-if="isShowExport"
            icon="export"
            type="primary"
            @click="exportClick"
          >
            导出
          </a-button>
        </div>
        <div class="right"></div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="auditStatus" slot-scope="auditStatus, record">
          <span v-if="record.auditStatus == 0">未提交</span>
          <span v-if="record.auditStatus == 1">提交未审核</span>
          <span v-if="record.auditStatus == 2">一级审核通过</span>
          <span v-if="record.auditStatus == 3">二级审核通过</span>
          <span v-if="record.auditStatus == 4">不通过</span>
        </span>
        <span
          slot="registerBuildStatus"
          slot-scope="registerBuildStatus, record"
        >
          <span v-if="record.registerBuildStatus == 0">未生成</span>
          <span v-if="record.registerBuildStatus == 1">已生成</span>
          <span v-if="record.registerBuildStatus == 2">已撤销</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a v-if="isShowDetail" @click="detailClick(record)">详情</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="getData.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="tableData.length > 0"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { DengjiListApi, DengjiResultApi } from "@/request/api/certificate.js";
import MyPagination from "@/components/pagination/MyPagination";
import moment from "moment";
import { codeFn } from "@/utils/tools";
import { domainName } from "@/config/index";
export default {
  components: { MyPagination },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    this.productCatValueArr.push(...dictionaryObj.proCat);
    this.autAcpMethodValueArr.push(...dictionaryObj.autAcp);
    this.productCreateNatValueArr.push(...dictionaryObj.proCreateNat);
    this.autBelMethodValueArr.push(...dictionaryObj.autBel);
    if (codeFn("/frame-web/admin/copyright/registration/productRegisterInfo"))
      this.isShowDetail = true;
    if (codeFn("/frame-web/admin/copyright/registration/export"))
      this.isShowExport = true;
    DengjiResultApi().then(({ code, data }) => {
      if (code == 200) {
        this.resultArr = data;
      }
    });
  },
  data() {
    return {
      resultArr: [],
      count: 0,
      rangePicker: [],
      rangePicker2: [],
      getData: {
        pageNo: 1,
        pageSize: 20,
        copyrightsName: "",
        productName: "",
        startTime: "",
        endTime: "",
        auditStatus: "",
        productCatValue: "",
        autAcpMethodValue: "",
        productCreateNatValue: "",
        autBelMethodValue: "",
        updStartTime: "",
        updEndTime: "",
      },
      auditStatusArr: [
        { key: "", value: "全部状态" },
        { key: 0, value: "未提交" },
        { key: 1, value: "提交未审核" },
        { key: 2, value: "一级审核通过" },
        { key: 3, value: "二级审核通过" },
        { key: 4, value: "不通过" },
      ],
      productCatValueArr: [{ key: "", value: "全部分类" }],
      autAcpMethodValueArr: [{ key: "", value: "全部方式" }],
      productCreateNatValueArr: [{ key: "", value: "全部性质" }],
      autBelMethodValueArr: [{ key: "", value: "全部方式" }],
      columns: [
        {
          title: "作品名称",
          dataIndex: "productName",
          key: "productName",
          width: "20%",
        },
        {
          title: "作者名称",
          dataIndex: "authorsName",
          key: "authorsName",
          width: "20%",
        },
        {
          title: "著作权人名称",
          dataIndex: "copyrightsName",
          key: "copyrightsName",
          width: "15%",
        },
        {
          title: "生成状态",
          dataIndex: "registerBuildStatus",
          key: "registerBuildStatus",
          width: "10%",
          scopedSlots: { customRender: "registerBuildStatus" },
        },
        {
          title: "登记状态",
          dataIndex: "auditStatus",
          key: "auditStatus",
          width: "10%",
          scopedSlots: { customRender: "auditStatus" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "10%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "更新时间",
          dataIndex: "updateTime",
          width: "10%",
          sorter: true,
          key: "updateTime",
          sorter: (a, b) => {
            let aTime = new Date(a.updateTime).getTime();
            let bTime = new Date(b.updateTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowDetail: false,
      isShowExport: false,
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path == "/certificate/dengji") {
          this.listFn();
        }
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    topClick(item) {
      this.getData.auditStatus = item.auditStatus;
      let myDate = `${new Date().getFullYear()}-${new Date().getMonth() +
        1}-${new Date().getDate()}`;
      this.rangePicker2 = [myDate, myDate];
      this.getData.updStartTime = myDate + " 00:00:00";
      this.getData.updEndTime = myDate + " 23:59:59";
      this.listFn();
    },
    exportClick() {
      window.location.href = `${domainName}/frame-web/admin/copyright/registration/export?pageNo=${this.getData.pageNo}&pageSize=${this.getData.pageSize}&auditStatus=${this.getData.auditStatus}&autAcpMethodValue=${this.getData.autAcpMethodValue}&autBelMethodValue=${this.getData.autBelMethodValue}&copyrightsName=${this.getData.copyrightsName}&endTime=${this.getData.endTime}&productCatValue=${this.getData.productCatValue}&productCreateNatValue=${this.getData.productCreateNatValue}&productName=${this.getData.productName}&startTime=${this.getData.startTime}`;
    },
    auditStatusChange(value) {
      this.getData.auditStatus = value;
      this.listFn();
    },
    productCatValueChange(value) {
      this.getData.productCatValue = value;
      this.listFn();
    },
    autAcpMethodValueChange(value) {
      this.getData.autAcpMethodValue = value;
      this.listFn();
    },
    productCreateNatValueChange(value) {
      this.getData.productCreateNatValue = value;
      this.listFn();
    },
    autBelMethodValueChange(value) {
      this.getData.autBelMethodValue = value;
      this.listFn();
    },
    detailClick(record) {
      this.$router.push(`/certificate/dengji/dengjiDetaile/${record.id}`);
    },
    searchClick() {
      this.getData.pageNo = 1;
      this.listFn();
    },
    // 注册日期改变
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.getData.startTime = dateString[0] + " 00:00:00";
        this.getData.endTime = dateString[1] + " 23:59:59";
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD"),
          moment(dateString[1], "YYYY-MM-DD"),
        ];
        this.getData.pageNo = 1;
        this.listFn();
      } else {
        this.getData.startTime = "";
        this.getData.endTime = "";
        this.rangePicker = [];
        this.getData.pageNo = 1;
        this.listFn();
      }
    },
    onChangeUpdDate(date, dateString) {
      if (dateString[0] != "") {
        this.getData.updStartTime = dateString[0] + " 00:00:00";
        this.getData.updEndTime = dateString[1] + " 23:59:59";
        this.rangePicker2 = [
          moment(dateString[0], "YYYY-MM-DD"),
          moment(dateString[1], "YYYY-MM-DD"),
        ];
        this.getData.pageNo = 1;
        this.listFn();
      } else {
        this.getData.updStartTime = "";
        this.getData.updEndTime = "";
        this.rangePicker2 = [];
        this.getData.pageNo = 1;
        this.listFn();
      }
    },
    listFn() {
      this.tableLoading = true;
      DengjiListApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.map((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#dengji");
    },
  },
};
</script>

<style lang="less" scoped>
#dengji {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top_tips {
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  div {
    margin-right: 20px;
  }
}
.top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .left {
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
    /deep/.ant-select {
      margin-left: 10px;
    }
  }
  .right {
    /deep/.ant-input {
      width: 200px;
    }
  }
}

.export {
  margin-top: 20px;
}
/deep/.ant-table-wrapper {
  margin-top: 20px;
}
.mr10 {
  margin-right: 10px;
}
</style>
